import { Loading } from 'element-ui';

import router from '/src/router';
import store from '/src/store';

let isFirstLoad = true;

function routerTo(guid, to, next) {
    if (to.meta.login && !guid) {
        // 必须登录
        next({ name: 'error404' });
    } else {
        next();
    }
}

/**
 * 格式化获取的url参数
 * @param {*} query
 * @returns 
 */
function queryFormat(query) {
    if (!query || typeof query !== 'object') {
        return {};
    }

    const tmpObj = {};

    Object.keys(query).forEach((key) => {
        if (query[key].constructor === Array) {
            // 值为数组，过滤包含大括号，并取值最长的一项
            tmpObj[key] = query[key].filter(item => !item.includes('{') && !item.includes('}')).reduce((prev, next) => {
                return next.length > prev.length ? next : prev;
            });
        } else {
            tmpObj[key] = query[key];
        }

        // 移除值中的大括号
        tmpObj[key] = tmpObj[key].replace(/\{|}/g, '');
    });

    return tmpObj;
}

router.beforeEach((to, from, next) => {
    const { token, userInfo } = store.getters;

    // console.log(to.name, token);
    // 首次加载
    if (isFirstLoad) {
        isFirstLoad = false;

        if (to.query) {
            store.dispatch('SetAccessQuery', JSON.stringify(queryFormat(to.query)));
        }
    }

    if (to.matched && !to.matched.length) {
        // 嵌套路径片段的路由记录不存在，表示路由中没有配置此页面
        next({ name: 'error404' });
    } else {
        if (token && !userInfo.guid) {
            // token存在，用户信息不存在，则请求用户信息
            const loadingObj = Loading.service({
                lock: true,
                text: '加载中...',
                // spinner: 'el-icon-loading'
            });

            store.dispatch('GetUserInfo').then((data) => {
                loadingObj.close();

                routerTo(data.guid, to, next);
            }).catch(() => {
                loadingObj.close();
            });
        } else {
            routerTo(userInfo.guid, to, next);
        }
    }
});

// router.afterEach(() => {
//     // 进度条结束
// });
