<template>
<el-dialog
        title="注册成功"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :custom-class="dialogCustomClass"
        width="500px"
        @close="handleToClose"
    >
<div v-if="isReg" class="reg-success">
<div class="reg-success-ico">
<i class="el-icon-circle-check"></i>
</div>
<div class="reg-success-text">
<p class="title">您已注册成功</p>
<p>设置你所需的客户行业地区，随时获取精准客户资源</p>
</div>
<div class="reg-success-btn">
<el-button type="primary" @click="handleToSetting">立即设置</el-button>
</div>
</div>
<div v-else class="login-container">
<div class="login-tips">
<i class="el-icon-wx"></i>
<span>微信登录或注册</span>
</div>

<div v-loading="loading" class="login-ewm" @click="handleCreateQr">
<el-image v-if="agreenValue" :src="loginEwm">
<div slot="error" class="image-slot">
<div class="ewm-error">
<i class="el-icon-refresh"></i>
<span>点击重新获取</span>
</div>
</div>
</el-image>

<div v-else class="login-ewm-txt">
<span>{{ agreenTips }}</span>
</div>
                
                <div class="login-ewm-tips">扫码关注公众号立即登录</div>
</div>

<div class="login-footer">
<el-checkbox v-model="agreenValue"></el-checkbox>
<span>登录即表示同意</span>
<router-link to="/about/service">《服务协议》</router-link>
<router-link to="/about/privacy">《隐私协议》</router-link>
</div>
</div>
</el-dialog>
</template>

<script>
// import dayjs from 'dayjs';

import { ApiLoginCreateQr, ApiLoginGetQrCodeState } from '/src/api/login';

import { track } from '/src/directive/track/sendData';

export default {
    name: "CLogin",
    // props: {
    //     exportTotal: {
    //         type: Number,
    //         default: 51629,
    //     },
    // },
    data() {
        return {
            dialogVisible: false,
            dialogCustomClass: 'login-dialog',
            loginSuccess: false,
            loading: false,

            loginEwm: '',
            // 轮询登录状态需要的key，生成二维码时返回
            loginKey: '',
            agreenValue: true,
            agreenTips: '请勾选和同意《服务协议》《隐私协议》',

            // 登录状态
            loginStatus: '',

            // 当前扫码动作是否是注册成功
            isReg: false,

            // 附带的参数
            loginQuery: {
                actID: '',
                key: '',
                word: '',
                service: '',
                tag: '',
                // 百度推广
                bd_vid: '',
                // 360推广
                qhclickid: '',
            },
        };
    },
    // watch: {
    //     dialogVisible(val) {
    //         // console.log('dialogVisible', val);
    //         if (this.loginStatus === 'success' && !val) {
    //             // console.log('dialogVisible', val);

    //             // $emit 莫名失效
    //             // this.$emit('success');

    //             window.location.reload();
    //         }
    //     },
    // },
    // computed: {
    //     userInfo () {
    //         return this.$store.getters.userInfo || {};
    //     },
    // },
    // created() {},
    mounted() {
        // this.handleOpen();
    },
    methods: {
        handleOpen() {
            this.isReg = false;
            this.agreenValue = true;
            this.dialogCustomClass = 'login-dialog';
            this.loginEwm = '';
            this.loginKey = '';
            this.loginStatus = '';
            this.dialogVisible = true;
            this.loginSuccess = false;

            // 获取进入页面时的参数
            const tmpQuery = this.$store.getters.accessQuery;

            if (tmpQuery) {
                this.loginQuery = JSON.parse(tmpQuery);
            }

            this.handleCreateQr();
        },

        // 生成二维码
        handleCreateQr() {
            const { loginQuery, agreenValue, agreenTips } = this;

            if (!agreenValue) {
                this.$message.error(agreenTips);

                return false;
            }

            // this.$emit('success');

            if (this.loading || this.loginKey) {
                return false;
            }

            this.loading = true;

            ApiLoginCreateQr({
                ...loginQuery,
            }).then((res) => {
                this.loading = false;

                if (res.data) {
                    this.loginEwm = res.data.url;
                    this.loginKey = res.data.key;

                    this.handleGetQrCodeState();
                } else {
                    this.$message.error('获取二维码数据为空！');
                }
            }).catch(() => {
                this.loading = false;
            });
        },
        
        // 轮询登录状态
        handleGetQrCodeState() {
            const { loginQuery, loginKey, dialogVisible } = this;

            if (!dialogVisible || !loginKey) {
                // 弹出框关闭，停止轮询
                return false;
            }

            ApiLoginGetQrCodeState({
                ...loginQuery,
                key: loginKey,
            }).then((res) => {
                if (res.data) {
                    const { key, value } = res.data;
            
                    this.loginStatus = key;

                    if (key === 'fail') {
                        setTimeout(() => {
                            this.handleGetQrCodeState();
                        }, 2000);
                    } else if (key === 'overdue') {
                        this.loginEwm = '';
                        this.loginKey = '';

                        this.$message.error('二维码过期，请重新获取！');                        
                    } else {
                        this.$message.success('登录成功！');

                        // // 是否刚注册 - 判断创建时间与当前时间在10分钟之内
                        // if (dayjs().diff(dayjs(parseInt(value.createTime)), 'minute') <= 10) {
                        //     this.dialogCustomClass = '';
                        //     this.isReg = true;
                        // } else {
                        //     this.dialogVisible = false;
                        // }
            
                        this.loginSuccess = true;
                        this.dialogVisible = false;

                        // 清空保留的参数
                        this.$store.dispatch('SetAccessQuery', '');
                        
                        this.$store.dispatch('SetToken', value.token).then(() => {
                            // 重新获取用户信息，更新vipFlag
                            this.$store.dispatch('GetUserInfo').then(() => {
                                // 重新获取用户信息 - 成功
                                this.$emit('success');
                            }).catch(() => {});
                        }).catch(() => {});
                    }
                }
            }).catch(() => {});
        },

        // 前往设置
        handleToSetting() {
            this.dialogVisible = false;

            const tmpRoute = this.$router.resolve({ path: '/user/setting' });

            window.open(tmpRoute.href, '_blank');
        },

        // 关闭的回调
        handleToClose() {
            if (!this.loginSuccess) {
                // 未支付成功，关闭弹出框
                // 上报
                track('t:click_signinpopup_close');
            }
            
            this.loginSuccess = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.login-container {
    text-align: center;
}

.login-tips {
    margin-bottom: 20px;

    font-size: 18px;
    line-height: 24px;

    .el-icon-wx {
        margin-right: 5px;
    }
}

.login-ewm {
    margin-bottom: 35px;

    .el-image,
    &-txt {
        display: inline-block;

        width: 185px;
        height: 185px;
        margin-bottom: 10px;
        background-color: #ddd;
    }

    &-txt {
        box-sizing: border-box;
        padding: 16% 20px 0;

        background-color: rgba(0,0,0,.3);
        color: #fff;
    }

    &-tips {
        font-size: 14px;
        color: #959595;
    }
}

.ewm-error {
    line-height: 185px;

    .el-icon-refresh {
        margin-right: 5px;

        font-size: 16px;
    }
}

.login-footer {
    height: 50px;
    background-color: #E5E5E5;

    line-height: 50px;

    a {
        color: #E23A37;
    }

    .el-checkbox {
        margin-right: 5px;
    }
}

.reg-success {
    padding: 32px 0;
    
    text-align: center;

    &-ico {
        height: 68px;

        .el-icon-circle-check {
            font-size: 46px;
            color: #E23A37;
        }
    }

    &-text {
        margin-bottom: 35px;

        font-size: 14px;
        color: #535353;
        line-height: 1.2;

        .title {
            margin-bottom: 15px;

            font-size: 24px;
            color: #1D1D1D;
        }
    }

    &-btn {
        .el-button {
            width: 180px;
            height: 50px;
            padding: 0;
            border-radius: 25px;

            line-height: 50px;
            font-size: 18px;
        }
    }
}
</style>
