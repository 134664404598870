import Cookies from 'js-cookie';

export function getCookie(key) {
    // 获取Cookie特殊判断
    return Cookies.get(key) || '';
}

export function setCookie(key, value) {
    return Cookies.set(key, value);
}

export function removeCookie(key) {
    return Cookies.remove(key);
}

export function getStorage(key) {
    return window.localStorage.getItem(key);
}

export function setStorage(key, value) {
    return window.localStorage.setItem(key, value);
}

export function removeStorage(key) {
    return window.localStorage.removeItem(key);
}
