import Layout from '/src/layout/Layout.vue'

// import homeIndex from '/src/views/home/index.vue'
const homeIndex  = () => import('/src/views/home/index.vue');

const homeRouter = {
    path: '/',
    component: Layout,
    // redirect: '/index',
    name: 'home',
    meta: { title: '首页' },
    children: [
        {
            path: '',
            component: homeIndex,
            name: 'homeIndex',
            meta: { title: '首页', navCurrent: 'index' },
        },
        
    ],
}

export default homeRouter
