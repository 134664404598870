import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
// import permission from './modules/permission';
// import tagsView from './modules/tagsView';
import getters from './getters';

// import { setToken } from '/src/utils/auth';

// // 每个子项目都会引用store，所以写在这里
// if (import.meta.env.VITE_NODE_ENV === 'development') {
//     // 本地开发时，JSESSIONID为登录后获取到的值
//     setToken('JSESSIONID', '3c1exhl4rkuu1o9lcfvz3gba3');
// }

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user,
    },
    getters,
});

export default store;

