import store from '/src/store';
import { ApiGetCitys, ApiGetIndustry } from '/src/api/common';
import { ApiOrderGetVipConfig } from '/src/api/order';
import { ApiExportGetExportConfig } from '/src/api/export';

export function getCitys(cb) {
    const { staticData } = store.getters;

    if (staticData.citys) {
        cb && cb(staticData.citys);

        return false;
    }

    ApiGetCitys().then((res) => {
        store.dispatch('SetStaticData', {
            key: 'citys',
            data: res.data,
        }).then(() => {
            cb && cb(res.data);
        }).catch(() => {});
    }).catch(() => {
        cb && cb();
    });
}

export function getCitysMap(cb) {
    const tmpCitysMap = {};

    getCitys((data) => {
        data.forEach((item) => {
            tmpCitysMap[item.code] = item.name;

            if (item.citys && item.citys.length) {
                item.citys.forEach((citem) => {
                    tmpCitysMap[citem.code] = citem.name;
                });
            }
        });

        cb && cb(tmpCitysMap);
    });
}

export function getIndustry(cb) {
    const { staticData } = store.getters;

    if (staticData.industry) {
        cb && cb(staticData.industry);

        return false;
    }

    ApiGetIndustry().then((res) => {
        store.dispatch('SetStaticData', {
            key: 'industry',
            data: res.data,
        }).then(() => {
            cb && cb(res.data);
        }).catch(() => {});
    }).catch(() => {
        cb && cb();
    });
}

export function getIndustryMap(cb) {
    const tmpIndustryMap = {};

    getIndustry((data) => {
        data.forEach((item) => {
            if (item.children && item.children.length) {
                item.children.forEach((citem) => {
                    tmpIndustryMap[citem.code] = citem.name;
                });
            }

            tmpIndustryMap[item.code] = item.name;
        });

        cb && cb(tmpIndustryMap);
    });
}

export function getStatic() {
    return {
        estiblishTime: [
            { label: '一年内', value: '1' },
            { label: '1-3年', value: '2' },
            { label: '3-5年', value: '3' },
            { label: '5-10年', value: '4' },
            { label: '10-15年', value: '5' },
            { label: '15年以上', value: '6' },
        ],
        regCapital: [
            { value: '1', label: '50万以内'},
            { value: '2', label: '50-100万'},
            { value: '3', label: '100-500万'},
            { value: '4', label: '500-1000万'},
            { value: '5', label: '1000-5000万'},
            { value: '6', label: '5000万以上'},
        ],
        companyType: [
            { value: '1', label: '有限责任公司'},
            { value: '2', label: '股份有限公司'},
            { value: '3', label: '个体户'},
            { value: '4', label: '个人独资企业'},
            { value: '5', label: '集体所有制'},
            { value: '6', label: '其他'},
        ],
        personnelScale: [
            { value: '1', label: '50人以内'},
            { value: '2', label: '50-100人'},
            { value: '3', label: '100-500人'},
            { value: '4', label: '500-1000人'},
            { value: '5', label: '1000-5000人'},
            { value: '6', label: '5000人以上'},
        ],
        phoneStatus: [
            { value: '1', label: '有手机/座机'},
            { value: '0', label: '无手机/座机'},
        ],
        emailStatus: [
            { value: '1', label: '有邮箱'},
            { value: '0', label: '无邮箱'},
        ],
        followStatus: [
            // { value: '', label: '无意向'},
            { value: '1', label: '已跟进'},
            { value: '0', label: '待跟进'},
        ],
        collectionStatus: [
            { value: '1', label: '已收藏'},
            { value: '0', label: '未收藏'},
        ],
    };
}

export function getStaticMap() {
    const tmpMap = {
        // 开票状态
        invoiceTypeMap: {
            '10': '未开票',
            '20': '开票中',
            '30': '开票失败',
            '40': '已开票',
            '50': '已寄出',
            '60': '未确认',
            '70': '已线下开票',
            '80': '暂不可开',
        },

        // 订单状态 10-待支付 20-已完成(已支付/已付款) 30-待确认 40-已关闭 50-已确认 60-确认失败
        orderStatusMap: {
            '10': '待支付',
            '20': '已完成(已支付/已付款)',
            '30': '待确认',
            '40': '已关闭',
            '50': '已确认',
            '60': '确认失败',
        },

        // 订单类型 后台提供 1-会员服务 2-续费会员 4-数据导出
        // 订单类型 1-660元三年会员 2-330元一年会员 3-包季服务 4-一次性消费导出
        orderTypeMap: {
            '1': '会员服务',
            '2': '续费会员',
            '3': '会员服务',
            '4': '数据导出',
            '5': '会员服务',
        },

        payTypeMap: {
            '1': '微信',
            '2': '支付宝',
        },

        // exportStatus	状态 1-生成中 2-已完成
        exportStatusMap: {
            '1': '生成中',
            '2': '已完成',
        },
        
        // exportType	导出类型 1-一次性购买导出 2-会员免费导出
        exportTypeMap: {
            '1': '一次性购买导出',
            '2': '会员免费导出',
        },
    };
    const tmpData = getStatic();
    
    Object.keys(tmpData).forEach((key) => {
        tmpMap[key] = {};

        tmpData[key].forEach((item) => {
            tmpMap[key][item.value] = item.label;
        });
    });

    return tmpMap;
}

// 支付套餐
export function getPayData(cb) {
    const { staticData } = store.getters;

    if (staticData.pay) {
        cb && cb(staticData.pay);

        return false;
    }

    ApiOrderGetVipConfig().then((res) => {
        store.dispatch('SetStaticData', {
            key: 'pay',
            data: res.data,
        }).then(() => {
            // console.log(res.data);
            cb && cb(res.data);
        }).catch(() => {});
    }).catch(() => {
        cb && cb();
    });
}

// 会员导出配置
export function getExportCfg(cb) {
    const { staticData } = store.getters;

    // console.log(staticData);

    if (staticData.export) {
        cb && cb(staticData.export);

        return false;
    }

    ApiExportGetExportConfig().then((res) => {
        // console.log(res.data);
        const { key, value } = res.data;

        const tmpData = {
            // 每日导出次数
            exportTimes: key,
            // 每次导出条数
            exportNumber: value,
        };

        store.dispatch('SetStaticData', {
            key: 'export',
            data: tmpData,
        }).then(() => {
            cb && cb(tmpData);
        }).catch(() => {});
    }).catch(() => {
        cb && cb();
    });
}