import Layout from '/src/layout/Layout.vue'

const article  = () => import('/src/views/about/article.vue');
// const aboutService  = () => import('/src/views/about/service.vue');
// const aboutPrivacy  = () => import('/src/views/about/privacy.vue');

const aboutRouter = {
    path: '/about',
    component: Layout,
    redirect: '/about/home',
    name: 'about',
    meta: { title: '太阳集团tcy8722' },
    children: [
        {
            path: 'home',
            component: article,
            name: 'aboutHome',
            meta: { title: '太阳集团tcy8722', page: 'about', navCurrent: 'about' },
        },
        {
            path: 'service',
            component: article,
            name: 'aboutService',
            meta: { title: '服务协议', page: 'service', navCurrent: 'about' },
        },
        {
            path: 'privacy',
            component: article,
            name: 'aboutPrivacy',
            meta: { title: '隐私协议', page: 'privacy', navCurrent: 'about' },
        },
        
    ],
}

export default aboutRouter
