import axios from 'axios';
import { MessageBox } from 'element-ui';

import store from '/src/store';

// 跨域允许携带cookie
axios.defaults.withCredentials = true;

// 创建axios实例
const service = axios.create({
    // baseURL: import.meta.env.VITE_BASE_API, // api的base_url，开发环境 /.env 生成环境 /.env.production
    baseURL: '', // api的base_url，开发环境 /.env 生成环境 /.env.production
    timeout: 30000, // 请求超时时间
    headers: { 'content-type': 'application/json' }, // 设置请求头，兼容性更好
});

// request拦截器
service.interceptors.request.use(
    (config) => {
        // 让每个请求携带自定义token 请根据实际情况自行修改
        config.headers['token'] = store.getters.token;

        return config;
    },
    (error) => {
        // Do something with request error
        console.log(error); // for debug
        Promise.reject(error);
    }
);

// respone拦截器
service.interceptors.response.use(
    (response) => {
        const { data } = response;

        // console.log(response);
        if (data) {
            return data;            
        } else {
            MessageBox({
                title: '数据获取失败',
                message: '请刷新页面重新尝试，或者联系我们的客服解决，错误信息：' + response.statusText,
                type: 'error',
            }).then(() => {}).catch(() => {});
        }

        return Promise.reject(); 
    },
    (error) => {
        MessageBox({
            title: '数据获取失败',
            message: '请刷新页面重新尝试，或者联系我们的客服解决，错误信息：' + JSON.stringify(error),
            type: 'error',
        }).then(() => {}).catch(() => {});

        return Promise.reject();
    }
);

export default service;
