import request from '/src/utils/request';

// 查询用户历史订单
export function ApiGetOrderList(query) {
    return request({
        url: '/order/getOrderList',
        method: 'post',
        data: query,
    });
}

// 用户后台--购买记录详情
export function ApiQueryOrderDetail(query) {
    return request({
        url: '/order/queryOrderDetail',
        method: 'post',
        data: query,
    });
}

// 根据用户及开通类型获取订单详情
export function ApiQueryOrderInfoMationByUserId(query) {
    return request({
        url: '/order/queryOrderInfoMationByUserId',
        method: 'post',
        data: query,
    });
}

// 生成订单
export function ApiCreateOrder(query) {
    return request({
        url: '/order/createOrder',
        method: 'post',
        data: query,
    });
}

// 订单查询
export function ApiOrderQuery(query) {
    return request({
        url: '/order/orderQuery',
        method: 'get',
        params: query,
    });
}

// 获取到处数据的金额
export function ApiGetOrderAmount(query) {
    return request({
        url: '/order/getOrderAmount',
        method: 'post',
        data: query,
    });
}

// 提交开票申请
export function ApiOrderApplyInvoice(query) {
    return request({
        url: '/order/applyInvoice',
        method: 'post',
        data: query,
    });
}

// 订单配置
export function ApiOrderGetVipConfig(query) {
    return request({
        url: '/order/getVipConfig',
        method: 'get',
        params: query,
    });
}