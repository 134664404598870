<template>
<div class="footer">
<div class="wrap">
<div class="info">
<div class="flink">
<div class="flink-title">友情链接</div>
<div class="flink-list">
<div class="flink-item">
<a
                                href=""
                                target="_blank"
                                >企业名录</a
                            >
</div>
<div class="flink-item">
<a
                                href="https://baogao.ruiyanshijie.com"
                                target="_blank"
                                >报告资源</a
                            >
</div>
</div>
</div>

<div class="ewm">
<el-image src="/images/gzh_430.jpg">
<div slot="error" class="image-slot">
<i class="el-icon-picture-outline"></i>
</div>
</el-image>
</div>
</div>

<div class="link">
<router-link to="/interface" target="_blank">接口开发文档</router-link>
<el-divider direction="vertical"></el-divider>
<router-link to="/about/home" target="_blank">太阳集团tcy8722</router-link>
<el-divider direction="vertical"></el-divider>
<router-link to="/about/privacy" target="_blank">隐私协议</router-link>
<el-divider direction="vertical"></el-divider>
<router-link to="/about/service" target="_blank">服务协议</router-link>
</div>

<div class="crp">
<span>Copyright © 太阳集团tcy8722. All Rights Reserved.&nbsp;</span>
<a href="https://beian.miit.gov.cn" target="_blank" rel="nofollow">粤ICP备2022038053号-1&nbsp;</a>
<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402005525" target="_blank" rel="nofollow">
<img src="/images/icon-portal.png" alt="portal" />
<span>粤公网安备 44030402005525号</span>
</a>
</div>
</div>

<div class="sign">
<div class="sign-item sign-contact">
<img src="/images/icon-contact.png" alt="客服" />

<div class="sign-box">
<div class="ewm-pic" v-track:exposure track-params="t:hover_assembly_customerservice">
<el-image fit="contain" src="/images/kf_300_300.png">
<div slot="error" class="image-slot">
<i class="el-icon-picture-outline"></i>
</div>
</el-image>

<div class="ewm-text">扫码添加<br />24小时客服在线答疑</div>
</div>
</div>
</div>
<div class="sign-item sign-ewm">
<img src="/images/icon-ewm.png" alt="二维码" />

<div class="sign-box">
<div class="ewm-pic" v-track:exposure track-params="t:hover_assembly_wechatqrcode">
<el-image fit="contain" src="/images/gzh_430.jpg">
<div slot="error" class="image-slot">
<i class="el-icon-picture-outline"></i>
</div>
</el-image>

<div class="ewm-text">微信公众号</div>
</div>
</div>
</div>
<div class="sign-item sign-top" @click="handleToTop">
<img src="/images/icon-top.png" alt="返回顶部" />
</div>
</div>
</div>
</template>

<script>
export default {
    name: "FooterBar",
    // components: {
    //     Breadcrumb,
    //     Hamburger,
    // },
    data() {
        return {
            timer: null,
        };
    },
    // computed: {
    //     activeName() {
    //         return this.$route.name;
    //     },
    // },
    mounted() {},
    methods: {
        handleToTop() {
            cancelAnimationFrame(this.timer);

            const self = this;

            self.timer = requestAnimationFrame(function fn() {
                const oTop = document.body.scrollTop || document.documentElement.scrollTop;
                
                if (oTop > 0) {
                    document.body.scrollTop = document.documentElement.scrollTop = oTop - 50;
                    
                    self.timer = requestAnimationFrame(fn);
                } else {
                    cancelAnimationFrame(self.timer);
                }
            });
        },
    },
};
</script>
