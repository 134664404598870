import dayjs from 'dayjs';

// 判断是否为空
const isNull = (val) => val === undefined || val === null;

// 为空占位符
export function FNull(value) {
    return isNull(value) ? '-' : value;
}

// 格式化为int类型整数
export function filterInt(value) {
    return isNull(value) ? '' : parseInt(value, 10);
}

/**
  * 日期格式化
  * @param number or object time 时间戳或日期对象
  * @param string format 日期格式 YYYY-MM-DDTHH:mm:ss
  */
export function filterDate(time, format) {
    if (!time || arguments.length === 0) {
        return '-';
    }

    // [YYYYescape] YYYY-MM-DDTHH:mm:ssZ[Z]
    return dayjs(time).format(format || 'YYYY-MM-DD');
}

/**
 * 千分位
 * @param {*} value
 * @param {*} num 保留小数位 默认值与toLocaleString一致保留3位
 */
export function filterThousandth(value, num) {
    const valLen = (value + '').split('.');
    let defaultDigits = num || 0;

    // 判断是否是空字符串，或者非数字字符串
    if (value === '' || isNaN(Number(value))) {
        return value;
    }

    if (!num && valLen[1]) {
        // 未限制保留小数位，且有小数点
        defaultDigits = valLen[1].length;
    }

    return (value || 0).toLocaleString(undefined, { minimumFractionDigits: defaultDigits, maximumFractionDigits: defaultDigits });
}
