import Layout from '/src/layout/Layout.vue'

const interfaceComponent  = () => import('/src/views/interface/index.vue');

const interfaceRouter = {
    path: '/interface',
    component: Layout,
    redirect: '/interface/home',
    name: 'interface',
    meta: { title: '接口开发文档' },
    children: [
        {   
            name: 'interfaceHome',
            component: interfaceComponent,
            path: 'home',
            meta: { title: '查询总数', navCurrent: 'interface' },
        },
        {   
            name: 'interfaceCompanyList',
            component: interfaceComponent,
            path: 'companylist',
            meta: { title: '企业查询列表', navCurrent: 'interface' },
        },
        {   
            name: 'interfaceBaseInfo',
            component: interfaceComponent,
            path: 'baseinfo',
            meta: { title: '企业基本信息查询', navCurrent: 'interface' },
        },
        {   
            name: 'interfaceContactInfo',
            component: interfaceComponent,
            path: 'contactinfo',
            meta: { title: '企业联系信息查询', navCurrent: 'interface' },
        },
        {   
            name: 'interfaceCitys',
            component: interfaceComponent,
            path: 'citys',
            meta: { title: '城市code', navCurrent: 'interface' },
        },
        {   
            name: 'interfaceIndustry',
            component: interfaceComponent,
            path: 'industry',
            meta: { title: '行业code', navCurrent: 'interface' },
        },
        {   
            name: 'interfaceStatusCode',
            component: interfaceComponent,
            path: 'statuscode',
            meta: { title: '请求状态码', navCurrent: 'interface' },
        },
        {   
            name: 'interfaceDemo',
            component: interfaceComponent,
            path: 'demo',
            meta: { title: '示例代码', navCurrent: 'interface' },
        },
    ],
}

export default interfaceRouter
