// import OrderPay from '/src/components/Dialog/orderPay.vue';
// import Login from '/src/components/Dialog/login.vue';
// import BuyVip from '/src/components/Dialog/buyVip.vue';

export default {
    // components: {
    //     OrderPay,
    //     Login,
    //     BuyVip,
    // },
    // data() {
    //     return {};
    // },
    computed: {
        userInfo () {
            return this.$store.getters.userInfo || {};
        },
        staticData () {
            return this.$store.getters.staticData;
        },
        vipFlag () {
            return this.$store.getters.vipFlag || false;
        },
    },
    methods: {
        // 登录成功
        handleLoginSuccess() {
            const { vipFlag } = this;

            if (!vipFlag) {
                // 开通会员
                this.$refs.BuyVip.handleOpen();

                return false;
            }
        },

        // 开通会员&导出 - 生成订单
        handlePayConfirm(data) {
            // 生成订单，跳转订单支付
            this.$refs.OrderPay.handleOpen(data);
        },

        // 支付成功
        handlePaySuccess() {            
            // 重新获取用户信息
            this.$store.dispatch('GetUserInfo');
        },

        // 登录及VIP判断
        handleIsLoginAndVip(noVip) {
            const { userInfo, vipFlag } = this;

            if (!userInfo.weiXinUnionId) {
                // 未登录
                this.$refs.Login.handleOpen();

                return false;
            } else if (!noVip && !vipFlag) {
                // 开通会员
                this.$refs.BuyVip.handleOpen();
                // this.$refs.OrderPay.handleOpen();

                return false;
            }

            return true;
        },
    },
}